exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-digital-garden-js": () => import("./../../../src/pages/digital-garden.js" /* webpackChunkName: "component---src-pages-digital-garden-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-speaking-js": () => import("./../../../src/pages/speaking.js" /* webpackChunkName: "component---src-pages-speaking-js" */),
  "component---src-templates-note-template-js": () => import("./../../../src/templates/noteTemplate.js" /* webpackChunkName: "component---src-templates-note-template-js" */)
}

