import { FaEnvelope, FaPhoneAlt, FaHome, FaDesktop, FaLinkedin, FaGithub } from 'react-icons/fa';
import { Link } from "../../../../src/components/link";
import { ContactDetails, Paragraph } from "../../../../src/components/resume/contact-details";
import * as React from 'react';
export default {
  FaEnvelope,
  FaPhoneAlt,
  FaHome,
  FaDesktop,
  FaLinkedin,
  FaGithub,
  Link,
  ContactDetails,
  Paragraph,
  React
};